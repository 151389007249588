<template>
    <div class="progress-chart">
        <div class="progress-chart__back">
            <div v-for="n in 10" :key="`back-col-${n}`" class="progress-chart__back-col" />
        </div>

        <div class="progress-chart__info page__row">

            <div class="progress-chart__legend page__col">
                <div v-for="(item, i) in legend" :key="`legend-${i}`"
                    class="progress-chart__legend-item"
                >
                    <span class="progress-chart__legend-item-color" :style="{backgroundColor: item.color}" />
                    <span class="progress-chart__legend-item-title">{{item.title}}</span>
                </div>
            </div>

            <div class="progress-chart__control page__button page__button--icon icon icon--more-vertical" />
        </div>

        <GChart
            type="ColumnChart"
            :data="chartData"
            :options="chartOptions"
            @ready="onChartReady"
            :resizeDebounce="10"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GChart } from 'vue-google-charts'

export default {
    components: {
        GChart,
    },

    data() {
        return {
            chartData: [
                ['Week', 'Audits Submitted', 'Audits Closed'],
                [37, 1, 0],
                [38, 2, 0],
                [39, 0, 1],
                [40, 2, 3],
                [41, 1, 0],
                [42, 2, 5],
                [43, 0, 1],
                [44, 3, 0],
                [45, 1, 1],
                [46, 0, 3],
            ],
        }
    },
    computed: {
        ...mapGetters(['is_mobile_style', 'is_tablet_style']),
        legend() {
            const colors = this.chartOptions.colors

            return this.chartData[0].slice(1).map((title, key) => ({color: colors[key], title}))
        },

        chartOptions() {
            const max_value = this.chartData.slice(1).reduce((acc, row) => {
                return Math.max(acc, row[1], row[2])
            }, 0)

            // to be changed with real dynamics data
            const h_ticks = this.chartData.slice(1).map(([week]) => ({v: week, f: `Wk${week}_2020`}))

            const height = this.is_tablet_style ? 500 : 700;
            const fontSize = this.is_tablet_style ? 14 : 18;

            return {
                height: height,
                fontSize: fontSize,
                backgroundColor: 'transparent',
                fontName: 'inherit',
                colors: ['#009681', '#94d600'],

                legend: { position: 'none' },
                bar: { groupWidth: '65%', width: '75%' },
                focusTarget: 'category',

                chartArea: {
                    left: '40',
                    width: '100%',
                    height: '84%',
                },

                hAxis: {
                    textStyle: { color: '#838383',},
                    gridlines: {
                        color: 'transparent',
                    },
                    minorGridlines: {
                        color: '#55c1e9',
                    },

                    ticks: h_ticks,

                    showTextEvery: 1,
                },

                vAxis: {
                    format: 'decimal',
                    textStyle: { color: '#838383',},
                    gridlines: {
                        color: '#55c1e9',
                    },
                    minorGridlines: {
                        color: 'transparent',
                    },
                    maxValue: max_value + 0.5,
                },
            }
        }
    },
    methods: {
        onChartReady (chart, google) {
        },
    }
}
</script>

<style lang="scss">
.progress-chart {
    position: relative;
    overflow: hidden;

    &__back {
        position: absolute;
        left: 40px;
        right: 0;
        top: 7%;
        bottom: 7%;

        display: flex;

        &-col {
            flex: 1 1 auto;

            &:nth-child(2n) {
                background-color: rgba($white, 0.8);
            }
        }

        @include media-bp(tab) {
            top: 10%;
            bottom: 7%;
        }
    }

    &__info {
        display: flex;
        justify-content: space-between;

        margin-bottom: 8px;
    }

    &__legend {
        display: flex;

        font-size: 18px;
        line-height: 1.22;

        &-item {
            display: flex;
            margin-right: 32px;

            &-color {
                width: 24px;
                height: 24px;
                border-radius: 4px;
                margin-right: 8px;
            }

            &-title {
                margin-top: 4px;
            }
        }
    }
}
</style>