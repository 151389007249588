<template>
    <div class="dashboard">
        <dashboard-controls />

        <app-tabs class="dashboard__tabs page__tabs page__tabs--secondary" v-model="tab" :tabs="tabs" type="secondary"/>

        <div v-if="tab_id == 'progress'" class="dashboard__chart">
            <progress-chart />
        </div>

        <div v-else class="dashboard_top10">
            TOP 10 SNAGLIST HERE
        </div>
    </div>
</template>

<script>
import appTabs from '@/components/app-tabs'
import dashboardControls from './controls.vue'
import progressChart from './progress-chart.vue'

export default {
    components: {
        appTabs,
        dashboardControls,
        progressChart,
    },

    data() {
        return {
            tabs: [
                { id: 'progress',      title: 'Progress' },
                { id: 'top10snaglist', title: 'Top 10 snaglist' },
            ],

            tab_id: 'progress',
        }
    },

    computed: {
        tab: {
            get() {
                return this.tabs.find( tab => tab.id == this.tab_id ) || this.tabs[0]
            },

            set(tab) {
                this.tab_id = tab.id
            }
        },
    }
}
</script>

<style lang="scss">
.dashboard {
}
</style>