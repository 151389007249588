<template>
    <div class="dashboard__controls page__row page__controls">
        <app-input-select class="page__input page__input--select"
            v-model="value1" :options="options1" />

        <app-input-select class="page__input page__input--select"
            v-model="value2" :options="options2" />

        <app-input-select class="page__input page__input--select"
            v-model="value3" :options="options3" />

        <button class="page__button btn btn--round btn--reload" />

    </div>
</template>

<script>
import appInputSelect from '@/components/app-input-select'

export default {
    components: {
        appInputSelect,
    },
    data() {
        return {
            options1: [
                {id: 1, name: 'facility_acceptance_v1'},
                {id: 2, name: 'facility_acceptance_v2'},
                {id: 3, name: 'facility_acceptance_v3'},
                {id: 4, name: 'facility_acceptance_v4'},
                {id: 5, name: 'facility_acceptance_v7'},
            ],
            value1: 3,

            options2: [
                {id: 0, name: 'all'},
                {id: 1, name: 'first option'},
                {id: 2, name: 'second option'},
                {id: 3, name: 'third option'},
                {id: 4, name: 'forth option'},
            ],
            value2: 0,

            options3: [
                {id: 0, name: 'All'},
                {id: 1, name: 'option A'},
                {id: 2, name: 'option B'},
                {id: 3, name: 'option C'},
                {id: 4, name: 'option D'},
                {id: 5, name: 'option E'},
            ],
            value3: 0,
        }
    },
}
</script>

<style lang="scss">
.dashboard__controls {

    flex-wrap: nowrap;

    @include media-bp(ltp) {
        flex-wrap: wrap;
    }

    .page {
        &__input {
            @include media-bp(ltp) {
                flex-basis: calc( 50% - 30px );
                max-width: calc( 50% - 30px );
            }
            @include media-bp(mob) {
                flex-basis: 100%;
                max-width: 100%;
            }
        }
    }

}
</style>